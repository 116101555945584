import React from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import {Task} from "../../types/Task";
import {DistributeChain} from "../../types/DistributeChain";
import {enqueueSnackbar} from "notistack";
import {eventEmitter} from "../../services/Events/EventEmitter";
import {EventsEnum} from "../../services/Events/EventsEnum";
import AdminButton from "../Shared/AdminButton";
import {useApi} from "../../context/ApiProvider";
import BooleanIcon from "../Shared/BooleanIcon";

interface TaskListComponentProps {
    distributeChain?: DistributeChain
    tasks: Task[]
}

const TaskListComponent: React.FC<TaskListComponentProps> = ({
    distributeChain,
    tasks
}) => {
    const api = useApi()
    const handleDelete = (wallet: Task) => () => {
        api?.delete(`/tasks/${wallet.id}`)
            .then(() => {
                enqueueSnackbar('Successfully deleted Task!', {variant: 'success'})
                eventEmitter.emit(EventsEnum.TASK_DELETED);
            })
            .catch((error) => {
                console.error('Error:', error)
                enqueueSnackbar('Failed to delete Task!', {variant: 'error'})
            })
    }

    return (
        <div>
            <h2>Tasks</h2>
            {distributeChain &&
                <AdminButton
                    variant="contained"
                    color="success"
                    href={`/distribute-chains/${distributeChain.id}/task/add`}
                    label="Add Task" />}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Wallet</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>Is Enabled</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks.map((row: Task) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.wallet_address}</TableCell>
                                <TableCell>{row.percentage}%</TableCell>
                                <TableCell><BooleanIcon value={row.is_enabled} /></TableCell>
                                <TableCell>
                                    <AdminButton
                                        variant="contained"
                                        color="warning"
                                        label="Edit"
                                        href={`/distribute-chains/${distributeChain?.id}/task/${row.id}/edit`}
                                    />

                                    <AdminButton
                                        variant="contained"
                                        color="error"
                                        onClick={handleDelete(row)}
                                        label="Remove" />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TaskListComponent;