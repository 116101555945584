import React, {useCallback, useEffect, useState} from 'react';
import { Select, MenuItem } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Container, Grid, TextField, Typography} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import {TaskType} from "../../types/TaskType";
import {TaskInput} from "../../types/Task";
import {useApi} from "../../context/ApiProvider";

type RouteParams = {
    id: string,
    task_id?: string
};

const UpsertTask: React.FC = () => {
    const api = useApi();
    const { id, task_id } = useParams<RouteParams>();
    const [type, setType] = useState<TaskType>(TaskType.DISTRIBUTE);
    const [name, setName] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isEnabled, setIsEnabled] = useState(true);
    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        if (task_id) {
            api?.get(`/tasks/${task_id}`)
                .then(response => {
                    if (!response.ok || !response.body) {
                        throw new Error('Failed to fetch!')
                    }

                    setType(response.body.type)
                    setName(response.body.name)
                    setWalletAddress(response.body.wallet_address)
                    setPercentage(response.body.percentage)
                    setIsEnabled(response.body.is_enabled)
                })
                .catch((error: any) => {
                    enqueueSnackbar(`Failed to fetch task data! Error: ${error.message}`, {variant: 'error'})
                });
        }
    }, [task_id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const wallet: TaskInput = {
            distribute_chain_id: Number(id),
            name: name,
            type: type,
            wallet_address: walletAddress,
            percentage: percentage,
            is_enabled: isEnabled
        };

        let response;
        if (task_id) {
            response = api?.put(`/tasks/${task_id}`, wallet)
        } else {
            response = api?.post(`/tasks`, wallet)
        }
        if (response === undefined) {
            enqueueSnackbar('Failed to add task!', {variant: 'error'});
            return;
        }

        response
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to add task!')
                }

                enqueueSnackbar('Successfully updated task!', {variant: 'success'})
                navigate(`/distribute-chains/${id}`)
            })
            .catch((error: AxiosError) => {
                console.error('Error:', error);

                if (error.response?.data) {
                    enqueueSnackbar(`Failed to update task. Error: ${error.response.data}`, {variant: 'error'})
                    return
                }

                enqueueSnackbar(`Failed to update task. Error: ${error}`, {variant: 'error'})
            });
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {task_id ? 'Update' : 'Add'} Task
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Select
                                fullWidth
                                value={type}
                                onChange={e => setType(e.target.value as TaskType)}
                                required
                            >
                                {Object.values(TaskType).map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Wallet Address" value={walletAddress} onChange={e => setWalletAddress(e.target.value)} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Percentage" value={percentage} onChange={e => setPercentage(Number(e.target.value))} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select
                                fullWidth
                                value={isEnabled}
                                onChange={e => setIsEnabled(e.target.value === 'true')}
                                required
                            >
                                <MenuItem value="true">Enabled</MenuItem>
                                <MenuItem value="false">Disabled</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                {task_id ? 'Update' : 'Add'} Task
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default UpsertTask;